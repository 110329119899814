// src/components/Sidebar.js
import React, { useState } from 'react';
import './Sidebar.css';
import { Link } from 'react-router-dom';  // Import Link for routing

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      {!isOpen && (
        <button className="sidebar-toggle" onClick={toggleSidebar}>
          ☰
        </button>
      )}
      <aside className={`sidebar ${isOpen ? 'open' : ''}`}>
        <div className="sidebar-header">
          <button className="close-btn" onClick={toggleSidebar}>
            &times;
          </button>
          <h2>Dashboard</h2>
        </div>
        <hr />
        <ul>
          <li><Link to="/dashboard" className="full-width-link">Case Tracker</Link></li>
          <li><Link to="/client-directory" className="full-width-link">Client Directory</Link></li>
          <li><Link to="/invoice-management" className="full-width-link">Billing & Invoices</Link></li>
          <li><Link to="/archive" className="full-width-link">Archived Cases</Link></li>
          <li><Link to="/accounting" className="full-width-link">Accounting</Link></li>
          <li><Link to="/register-user" className="full-width-link">Register User</Link></li>
        </ul>
      </aside>
    </div>
  );
};

export default Sidebar;
