import React, { useEffect, useState } from "react";
import axios from "axios";
import DashboardHeader from "../components/DashboardHeader";
import Sidebar from "../components/Sidebar";
import DashboardFooter from "../components/DashboardFooter";
import "./RegisterUser.css"; // Import CSS for styling
import { useNavigate } from "react-router-dom";
import { FaTrash, FaUser, FaEnvelope, FaLock } from "react-icons/fa"; // Notice FaEdit is removed

const RegisterUser = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  // Removed everything related to editing/modals

  // Checkbox logic for selecting users
  const handleCheckboxChange = (userId) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.includes(userId)
        ? prevSelected.filter((id) => id !== userId)
        : [...prevSelected, userId]
    );
  };

  // Form state
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    role: "user",
  });

  const [error, setError] = useState("");

  // Fetch users on mount
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem("access_token");
        if (!token) {
          console.error("No JWT token found, redirecting to login.");
          navigate("/signin");
          return;
        }

        const response = await axios.get(
          "https://app.trackmydocs.cloud/api/auth/existing-users/",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (Array.isArray(response.data)) {
          setUsers(response.data);
        } else {
          console.error("Invalid response format");
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [navigate]);

  // Handle input change for registration form
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission for new user
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      const token = localStorage.getItem("access_token");
      if (!token) {
        console.error("No JWT token found.");
        return;
      }

      const response = await axios.post(
        "https://app.trackmydocs.cloud/api/auth/register/",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        alert("User registered successfully!");
        // Reset form fields
        setFormData({
          username: "",
          first_name: "",
          last_name: "",
          email: "",
          password: "",
          confirmPassword: "",
          role: "user",
        });
        setError("");

        // Refresh the page
        window.location.reload();
      }
    } catch (error) {
      console.error("Error registering user:", error);
      setError("Registration failed. Try again.");
    }
  };

  // Handle Delete Users
  const handleDeleteUsers = async () => {
    if (selectedUsers.length === 0) {
      alert("No users selected for deletion.");
      return;
    }

    try {
      const token = localStorage.getItem("access_token");
      if (!token) {
        console.error("No JWT token found.");
        return;
      }

      const response = await axios.post(
        "https://app.trackmydocs.cloud/api/auth/del-user/",
        { user_ids: selectedUsers },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        alert("Users deleted successfully!");
        setUsers((prevUsers) =>
          prevUsers.filter((user) => !selectedUsers.includes(user.id))
        );
        setSelectedUsers([]); // Reset selection
      }
    } catch (error) {
      console.error("Error deleting users:", error);
    }
  };

  return (
    <div className="ru-page-container">
      <DashboardHeader />
      <Sidebar />
      <div className="ru-main-content">
        <div className="ru-two-columns">
          {/* First Div - Register User */}
          <div className="ru-content-area">
            <form className="ru-register-form" onSubmit={handleSubmit}>
              {error && <p className="ru-error">{error}</p>}
              <h3>Register Users</h3>

              <div className="ru-input-group">
                <FaUser className="ru-icon" />
                <input
                  type="text"
                  placeholder="First Name"
                  value={formData.first_name}
                  onChange={(e) =>
                    setFormData({ ...formData, first_name: e.target.value })
                  }
                  required
                />
              </div>

              <div className="ru-input-group">
                <FaUser className="ru-icon" />
                <input
                  type="text"
                  placeholder="Last Name"
                  value={formData.last_name}
                  onChange={(e) =>
                    setFormData({ ...formData, last_name: e.target.value })
                  }
                  required
                />
              </div>

              <div className="ru-input-group">
                <FaUser className="ru-icon" />
                <input
                  type="text"
                  name="username"
                  placeholder="Username"
                  value={formData.username}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="ru-input-group">
                <FaEnvelope className="ru-icon" />
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="ru-input-group">
                <FaLock className="ru-icon" />
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="ru-input-group">
                <FaLock className="ru-icon" />
                <input
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="ru-input-group">
                <select
                  name="role"
                  value={formData.role}
                  onChange={handleChange}
                >
                  <option value="user">User</option>
                  <option value="admin">Admin</option>
                </select>
              </div>

              <button type="submit" className="ru-register-btn">
                Register
              </button>
            </form>
          </div>

          {/* Second Div - Existing Users */}
          <div className="ru-content-area">

            <div className="ru-existingusers">
              {/* A new header bar containing the heading & conditional Delete button */}
              <div className="ru-users-header">
                <h3>Existing Users</h3>
                {selectedUsers.length > 0 && (
                  <button className="ru-delete-btn" onClick={handleDeleteUsers}>
                    <FaTrash /> Delete
                  </button>
                )}
              </div>

              <ol>
                {users.length > 0 ? (
                  users.map((user, index) => (
                    <li key={user.id}>
                      <span className="ru-user-id">{index + 1}</span>
                      <input
                        type="checkbox"
                        className="ru-user-checkbox"
                        checked={selectedUsers.includes(user.id)}
                        onChange={() => handleCheckboxChange(user.id)}
                      />
                      <mark>{user.full_name || "No Name"}</mark>
                      <small>{user.role}</small>
                    </li>
                  ))
                ) : (
                  <p>No users found.</p>
                )}
              </ol>
            </div>
          </div>
        </div>
      </div>
      <DashboardFooter />
    </div>
  );
};

export default RegisterUser;
