import React, { useEffect, useState } from "react";
import axios from "axios";
import DashboardHeader from "../components/DashboardHeader";
import Sidebar from "../components/Sidebar";
import DashboardFooter from "../components/DashboardFooter";
import "./Accounting.css";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";

const Accounting = () => {
  const navigate = useNavigate();

  // State for Landlord Advice Fees
  const [fees, setFees] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  // State for Other Fees
  const [otherFees, setOtherFees] = useState([]);
  const [searchOtherInput, setSearchOtherInput] = useState("");

  // Fetch Landlord Advice Fee Data
  useEffect(() => {
    const fetchFees = async () => {
      const token = localStorage.getItem("access_token");
      if (!token) {
        console.error("No JWT token found, redirecting to login.");
        navigate("/signin");
        return;
      }

      try {
        const response = await axios.get(
          "https://app.trackmydocs.cloud/api/invoices/landlord-fees/",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log("Fetched fees:", response.data);

        // Sort the fees by invoice number before setting state
        const sortedFees = response.data.sort((a, b) =>
          a.invoice_number.localeCompare(b.invoice_number)
        );
        setFees(sortedFees);
      } catch (error) {
        console.error("Error fetching fees:", error);
        if (error.response && error.response.status === 401) {
          navigate("/signin");
        }
      }
    };

    const fetchOtherFees = async () => {
      const token = localStorage.getItem("access_token");
      if (!token) {
        console.error("No JWT token found, redirecting to login.");
        navigate("/signin");
        return;
      }

      try {
        const response = await axios.get(
          "https://app.trackmydocs.cloud/api/invoices/other-fees/",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log("Fetched Other Fees:", response.data);

        // Sort the fees by invoice number before setting state
        const sortedOtherFees = response.data.sort((a, b) =>
          a.invoice_number.localeCompare(b.invoice_number)
        );
        setOtherFees(sortedOtherFees);
      } catch (error) {
        console.error("Error fetching Other Fees:", error);
        if (error.response && error.response.status === 401) {
          navigate("/signin");
        }
      }
    };

    fetchFees();
    fetchOtherFees();
  }, [navigate]);

  // Search & Filtering for Landlord Fees
  const handleSearchChange = (event) => {
    setSearchInput(event.target.value.toLowerCase());
  };

  const filteredFees = fees.filter(
    (feeItem) =>
      feeItem.invoice_number.toLowerCase().includes(searchInput) ||
      feeItem.our_reference.toLowerCase().includes(searchInput) ||
      feeItem.client.toLowerCase().includes(searchInput)
  );

  // Search & Filtering for Other Fees
  const handleOtherSearchChange = (event) => {
    setSearchOtherInput(event.target.value.toLowerCase());
  };

  const filteredOtherFees = otherFees.filter(
    (feeItem) =>
      feeItem.invoice_number.toLowerCase().includes(searchOtherInput) ||
      feeItem.our_reference.toLowerCase().includes(searchOtherInput) ||
      feeItem.client.toLowerCase().includes(searchOtherInput)
  );

    // Function to get current timestamp
    const getCurrentTimestamp = () => {
      const now = new Date();
      const date = now.toISOString().slice(0, 10).replace(/-/g, ""); // YYYYMMDD
      const time = now.toTimeString().slice(0, 8).replace(/:/g, ""); // HHMMSS
      return `${date}_${time}`;
    };
  
    // Export function for Landlord Fees
    const exportLandlordFeesToExcel = () => {
      const timestamp = getCurrentTimestamp();
      const fileName = `landlord_fee_${timestamp}.xlsx`;
  
      const worksheet = XLSX.utils.json_to_sheet(fees);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Landlord Fees");
  
      XLSX.writeFile(workbook, fileName);
    };
  
    // Export function for Other Fees
    const exportOtherFeesToExcel = () => {
      const timestamp = getCurrentTimestamp();
      const fileName = `other_fee_${timestamp}.xlsx`;
  
      const worksheet = XLSX.utils.json_to_sheet(otherFees);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Other Fees");
  
      XLSX.writeFile(workbook, fileName);
    };

  return (
    <div className="acc-land-page-container">
      <DashboardHeader />
      <Sidebar />
      <div className="acc-land-main-content">
        <div className="acc-land-content-area">
          {/* Landlord Advice Fee Section */}
          <div className="acc-land-recent-fees">
            <div className="acc-land-recent-fees-header">
              <h2>Landlord Advice Fee</h2>
              <input
                type="text"
                className="acc-land-search-bar"
                placeholder="Search invoices, references, clients..."
                onChange={handleSearchChange}
                value={searchInput}
              />
              <button className="acc-land-export-btn" onClick={exportLandlordFeesToExcel}>
                Export to Excel
              </button>
            </div>
            <table className="acc-land-fees-table">
              <thead>
                <tr>
                  <th>Invoice Number</th>
                  <th>Invoice Type</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Our Reference</th>
                  <th>Client</th>
                </tr>
              </thead>
              <tbody>
                {filteredFees.length > 0 ? (
                  filteredFees.map((feeItem) => (
                    <tr key={feeItem.id}>
                      <td>{feeItem.invoice_number}</td>
                      <td>{feeItem.invoice_type}</td>
                      <td>{feeItem.amount}</td>
                      <td>{feeItem.status}</td>
                      <td>{feeItem.our_reference}</td>
                      <td>{feeItem.client}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6">No fees found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Other Fee Section */}
          <div className="acc-land-other-fees">
            <div className="acc-land-other-fees-header">
              <h2>Other Fee</h2>
              <input
                type="text"
                className="acc-land-other-search-bar"
                placeholder="Search invoices, references, clients..."
                onChange={handleOtherSearchChange}
                value={searchOtherInput}
              />
              <button className="acc-land-export-btn" onClick={exportOtherFeesToExcel}>
                Export to Excel
              </button>
            </div>
            <table className="acc-land-other-fees-table">
              <thead>
                <tr>
                  <th>Invoice Number</th>
                  <th>Invoice Type</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Our Reference</th>
                  <th>Client</th>
                </tr>
              </thead>
              <tbody>
                {filteredOtherFees.length > 0 ? (
                  filteredOtherFees.map((feeItem) => (
                    <tr key={feeItem.id}>
                      <td>{feeItem.invoice_number}</td>
                      <td>{feeItem.invoice_type}</td>
                      <td>{feeItem.amount}</td>
                      <td>{feeItem.status}</td>
                      <td>{feeItem.our_reference}</td>
                      <td>{feeItem.client}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6">No fees found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <DashboardFooter />
    </div>
  );
};

export default Accounting;
