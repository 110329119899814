import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import DashboardHeader from '../components/DashboardHeader';
import Sidebar from '../components/Sidebar';
import DashboardFooter from '../components/DashboardFooter';
import './InvoiceInfo.css';
import Modal from '../components/Modal';

const InvoiceInfo = () => {
  const { invoice_number } = useParams();
  const navigate = useNavigate();

  //state to fetch invoice details for iminfo-detail-container div
  const [invoiceDetails, setInvoiceDetails] = useState(null);

  //state to fetch files from S3 bucket and generate documents in iminfo-documents div
  const [files, setFiles] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState(true);
  const [fileError, setFileError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const [loadingInvoice, setLoadingInvoice] = useState(true);
  const [error, setError] = useState(null);

  // State to fetch and display invoice data in the iminfo-display div
  const [invoiceData, setInvoiceData] = useState([]);  // Initialize as an empty array to handle multiple records
  const [loadingInvoiceData, setLoadingInvoiceData] = useState(true);
  const [invoiceDataError, setInvoiceDataError] = useState(null);

  //State to display invoice totals
  const [invoiceTotals, setInvoiceTotals] = useState({
    totalCharges: 0,
    totalVatAmount: 0,
    totalNetAmount: 0,
    totalInvoiceAmount: 0
  });
  const [loadingTotals, setLoadingTotals] = useState(true);
  const [totalsError, setTotalsError] = useState(null);

  //State for Modal Visibility
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  }; 
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // State to manage form data for adding additional fees
  const [additionalFeeData, setAdditionalFeeData] = useState({
    invoiceType: '',
    charges: '',
    vatAmount: '',
    totalNetAmount: '',
    invoiceTotal: ''
  });

  //State to handle the delete button on the table
  const [allSelected, setAllSelected] = useState(false); // State to track if all checkboxes are selected

  //Handler to select and delete the invoices 
  const handleSelectFile = (index) => {
    const updatedFiles = files.map((file, idx) =>
      idx === index ? { ...file, isSelected: !file.isSelected } : file
    );
    setFiles(updatedFiles);
  };
  
  const handleSelectAllFiles = () => {
    const areAllSelected = files.every(file => file.isSelected);
    const updatedFiles = files.map(file => ({ ...file, isSelected: !areAllSelected }));
    setFiles(updatedFiles);
    setAllSelected(!areAllSelected); // Update the `allSelected` state
  };

  //States to enable Editing Invoice Details
  const [isEditing, setIsEditing] = useState(false);
  const [editableDetails, setEditableDetails] = useState({
    client: invoiceDetails?.client || '',
    status: invoiceDetails?.status || '',
    invoice_date: invoiceDetails?.invoice_date || '',
    property_address: invoiceDetails?.property_address || '',
    our_ref: invoiceDetails?.our_ref || '',
    vat_no: invoiceDetails?.vat_no || '',
  });

  // When invoiceDetails are fetched, update editableDetails:
  useEffect(() => {
    if (invoiceDetails) {
      setEditableDetails({
        client: invoiceDetails.client,
        status: invoiceDetails.status,
        invoice_date: invoiceDetails.invoice_date,
        property_address: invoiceDetails.property_address,
        our_ref: invoiceDetails.our_ref,
        vat_no: invoiceDetails.vat_no,
      });
    }
  }, [invoiceDetails]);

  // Toggle edit mode:
  const handleToggleEditMode = () => {
    setIsEditing(!isEditing);
  };
  
  // ****END of Edit States**** 

  // Submit updated invoice details:
  const handleSubmitEdit = async () => {
    const token = localStorage.getItem('access_token');
    if (!token) {
      alert('You must be logged in to update invoice details.');
      return;
    }

    try {
      // Assumes an endpoint for updating invoice details exists:
      const response = await axios.put(
        `https://app.trackmydocs.cloud/api/invoices/update/${invoice_number}/`,
        editableDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setIsEditing(false);
        setInvoiceDetails(response.data); // Update with fresh data from the backend
        alert('Invoice details updated successfully!');
        window.location.reload(); // Optional: reload to reflect changes
      } else {
        alert('Error updating invoice details.');
      }
    } catch (error) {
      console.error('Error updating invoice details:', error);
      alert('An error occurred while updating invoice details. Please try again.');
    }
  };

  // States to manage the edit functionality on the Amount Div
  const [isEditingTotals, setIsEditingTotals] = useState(false);
  const [editableTotals, setEditableTotals] = useState({
    total_charges: invoiceTotals.totalCharges,
    total_vat_amount: invoiceTotals.totalVatAmount,
    total_net_amount: invoiceTotals.totalNetAmount,
    total_invoice_amount: invoiceTotals.totalInvoiceAmount,
  });

  // When invoiceTotals are fetched or updated, update editableTotals:
  useEffect(() => {
    if (invoiceTotals) {
      setEditableTotals({
        total_charges: invoiceTotals.totalCharges,
        total_vat_amount: invoiceTotals.totalVatAmount,
        total_net_amount: invoiceTotals.totalNetAmount,
        total_invoice_amount: invoiceTotals.totalInvoiceAmount,
      });
    }
  }, [invoiceTotals]);

  // Toggle function:
  const handleToggleEditTotals = () => {
    setIsEditingTotals(!isEditingTotals);
  };

  // Submit updated totals:
  const handleSubmitEditTotals = async () => {
    const token = localStorage.getItem('access_token');
    if (!token) {
      alert('You must be logged in.');
      return;
    }
    try {
      const response = await axios.put(
        `https://app.trackmydocs.cloud/api/invoices/update-total/${invoice_number}/`,
        editableTotals,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status === 200) {
        alert(response.data.message);
        setIsEditingTotals(false);
        // Optionally, refresh totals from the backend:
        // (Assume you have a fetchInvoiceTotals function)
        // fetchInvoiceTotals(token);
      } else {
        alert('Error updating invoice totals.');
      }
    } catch (error) {
      console.error('Error updating totals:', error);
      alert('An error occurred while updating totals.');
    }
  };

  //****END of Amount Divs Edit Functionality****** */
  
  //Handler to delete selected files from S3 bucket
  const handleDeleteSelectedFiles = async () => {
    const selectedFiles = files.filter(file => file.isSelected).map(file => file.name);
    const token = localStorage.getItem('access_token');
  
    if (selectedFiles.length === 0) {
      alert('No files selected for deletion.');
      return;
    }
  
    try {
      const response = await axios.post(
        'https://app.trackmydocs.cloud/api/invoices/delete-invoice-files/', // Replace with your actual endpoint
        { invoice_number, files: selectedFiles },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 200) {
        alert(response.data.message || 'Files deleted successfully!');
        const remainingFiles = files.filter(file => !file.isSelected);
        setFiles(remainingFiles);
      } else {
        alert('Failed to delete files. Please try again.');
      }
    } catch (error) {
      console.error('Error deleting files:', error);
      alert('An error occurred while deleting the files. Please try again.');
    }
  };
  

  // Effect to auto-calculate VAT, Total Net Amount, and Invoice Total based on charges
  useEffect(() => {
    const charges = parseFloat(additionalFeeData.charges) || 0; // Handle NaN for empty inputs
    const vatAmount = charges * 0.20; // 20% VAT
    setAdditionalFeeData(prev => ({
      ...prev,
      vatAmount: vatAmount.toFixed(2), // Formats to 2 decimal places
      totalNetAmount: charges.toFixed(2),
      invoiceTotal: (charges + vatAmount).toFixed(2)
    }));
  }, [additionalFeeData.charges]);

  // Function to submit the additional fee data to the backend
  const submitAdditionalFee = async () => {
    const dataToSend = {
      invoice_number: invoiceDetails.invoice_number,
      our_ref: invoiceDetails.our_ref,
      invoice_type: additionalFeeData.invoiceType,
      charges: additionalFeeData.charges,
      vat_amount: additionalFeeData.vatAmount,
      total_net_amount: additionalFeeData.totalNetAmount,
      invoice_total: additionalFeeData.invoiceTotal,
    };

    try {
      const response = await axios.post(
        'https://app.trackmydocs.cloud/api/invoices/add-fee/',
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 201) {
        alert('Additional fee added successfully!');
        handleCloseModal(); // Only close modal after successful submission
        fetchInvoiceData(localStorage.getItem('access_token')); // Fetch the updated invoice data to refresh the table
        window.location.reload(); // Reload the page to fetch updated data
      }
    } catch (error) {
      console.error('Failed to add additional fee:', error.response?.data || error.message);
      alert('Failed to add additional fee: ' + (error.response?.data.error || error.message));
    }
  };
  
  // Fetch invoice details for iminfo-detail-container div
  useEffect(() => {
    const fetchInvoiceDetails = async () => {
      const token = localStorage.getItem('access_token');

      if (!token) {
        navigate('/signin');
        return;
      }

      try {
        const response = await axios.get(`https://app.trackmydocs.cloud/api/invoices/disp-invoice/${invoice_number}/`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0'
          },
        });

        if (response.status === 200) {
          setInvoiceDetails(response.data);
        } else {
          setError('Invoice not found');
        }
        setLoadingInvoice(false);
      } catch (error) {
        setError('Error fetching invoice details');
        setLoadingInvoice(false);
      }
    };

    fetchInvoiceDetails();
  }, [invoice_number, navigate]);

  // Function to fetch combined invoice entries for the iminfo-display div
  const fetchInvoiceData = useCallback(async (token) => {
    setLoadingInvoiceData(true);
    setInvoiceDataError(null);

    try {
      const response = await axios.get(
        `https://app.trackmydocs.cloud/api/invoices/id-table/${invoice_number}/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200 && response.data.length > 0) {
        setInvoiceData(response.data);  // Set the array of invoice data
      } else {
        setInvoiceDataError('No invoice details found.');
      }

      setLoadingInvoiceData(false);
    } catch (error) {
      setInvoiceDataError('Error fetching invoice data. Please try again later.');
      setLoadingInvoiceData(false);
    }
  }, [invoice_number]);

  // Fetch invoice data when the component loads or invoice_number changes
  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      fetchInvoiceData(token);
    }
  }, [invoice_number, fetchInvoiceData]);

  //Function to fetch data for iminfo-total div
  useEffect(() => {
    const fetchInvoiceTotals = async () => {
      setLoadingTotals(true);
      setTotalsError(null);
  
      const token = localStorage.getItem('access_token');
      if (!token) {
        navigate('/signin');
        return;
      }
  
      try {
        const response = await axios.get(`https://app.trackmydocs.cloud/api/invoices/invoice-total/${invoice_number}/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (response.status === 200) {
          setInvoiceTotals({
            totalCharges: response.data.total_charges,
            totalVatAmount: response.data.total_vat_amount,
            totalNetAmount: response.data.total_net_amount,
            totalInvoiceAmount: response.data.total_invoice_amount
          });
        } else {
          setTotalsError('Failed to fetch invoice totals');
        }
      } catch (error) {
        setTotalsError('Error fetching invoice totals');
      }
  
      setLoadingTotals(false);
    };
  
    fetchInvoiceTotals();
  }, [invoice_number, navigate]);

  // Function to fetch files from the S3 bucket
  const fetchFiles = useCallback(async (token) => {
    setLoadingFiles(true);
    setFileError(null);

    try {
      const case_number = invoiceDetails?.our_ref.replace(/\//g, '-');
      if (!case_number) {
        throw new Error('Our Ref (case number) is not available');
      }
      
      const response = await axios.get(
        `https://app.trackmydocs.cloud/api/documents/list-invoice-files/${case_number}/`, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200 && response.data.files.length > 0) {
        setFiles(response.data.files);
      } else {
        setFileError('No invoice documents found.');
      }

      setLoadingFiles(false);
    } catch (error) {
      setFileError('Error fetching files. Please try again later.');
      setLoadingFiles(false);
    }
  }, [invoiceDetails]);

  // Fetch files when invoiceDetails are available
  useEffect(() => {
    if (invoiceDetails) {
      const token = localStorage.getItem('access_token');
      if (token) {
        fetchFiles(token);
      }
    }
  }, [invoiceDetails, fetchFiles]);

  // States and Handler to delete the records from the Invoice Combined Table
  // New state to track selected combined records (from tmd_landlordadviceuk_invoices_combined)
  const [selectedCombinedRecords, setSelectedCombinedRecords] = useState([]);

  // Handler to toggle a single record's selection by its ID
  const handleSelectCombinedRecord = (id) => {
    if (selectedCombinedRecords.includes(id)) {
      setSelectedCombinedRecords(selectedCombinedRecords.filter(item => item !== id));
    } else {
      setSelectedCombinedRecords([...selectedCombinedRecords, id]);
    }
  };

  // Handler for "select all" functionality in the combined records table
  const handleSelectAllCombinedRecords = () => {
    if (selectedCombinedRecords.length === invoiceData.length) {
      setSelectedCombinedRecords([]);
    } else {
      setSelectedCombinedRecords(invoiceData.map(item => item.id));
    }
  };

  // Handler to delete selected combined records
  const handleDeleteCombinedRecords = async () => {
    const token = localStorage.getItem('access_token');
    if (!token) {
      alert("You must be logged in");
      return;
    }
    if (selectedCombinedRecords.length === 0) {
      alert("No records selected for deletion");
      return;
    }
    try {
      const response = await axios.post(
        "https://app.trackmydocs.cloud/api/invoices/delete-invoice-combine-records/",
        { ids: selectedCombinedRecords },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        }
      );
      if (response.status === 200) {
        alert(response.data.message);
        // Refresh the combined records data (assuming fetchInvoiceData fetches them)
        fetchInvoiceData(token);
        // Clear selection
        setSelectedCombinedRecords([]);
      } else {
        alert("Failed to delete records");
      }
    } catch (error) {
      console.error("Error deleting records", error);
      alert("An error occurred while deleting records");
    }
  };

  // "Generate Invoice Document" button to generate documents in S3 bucket
  const handleGenerateInvoiceDoc = async () => {
    const token = localStorage.getItem('access_token');

    if (!token) {
      navigate('/signin');
      return;
    }

    try {
      const response = await axios.post(
        `https://app.trackmydocs.cloud/api/documents/gen-invoice-doc/`, 
        { invoice_number },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.status === 200 || response.status === 201) {
        setSuccessMessage(response.data.message || 'Invoice document generated successfully!');
        alert(response.data.message || 'Invoice document generated successfully!');
        fetchFiles(token);  // Refresh the file list after the document is created
      } else {
        alert('Unexpected response from the server.');
      }
    } catch (error) {
      console.error('Error generating document:', error);
      alert('Error generating document. Please try again later.');
    }
  };

  if (loadingInvoice) return <div>Loading invoice details...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="iminfo-page-container">
      <DashboardHeader />
      <Sidebar />

      <div className="iminfo-main-content">
        <div className="iminfo-content-area">
          <div className="iminfo-detail-container">
            {/* Always render the Edit/Cancel button in the top-right corner */}
            <button className="iminfo-edit-button" onClick={handleToggleEditMode}>
              {isEditing ? 'Cancel' : 'Edit'}
            </button>

            {isEditing ? (
              <div>
                <h2>Invoice #{invoice_number}</h2>

                <label className="iminfo-label">
                  Client:
                  <input
                    type="text"
                    className="iminfo-input"
                    value={editableDetails.client}
                    onChange={(e) =>
                      setEditableDetails({ ...editableDetails, client: e.target.value })
                    }
                  />
                </label>

                <label className="iminfo-label">
                  Status:
                  <input
                    type="text"
                    className="iminfo-input"
                    value={editableDetails.status}
                    onChange={(e) =>
                      setEditableDetails({ ...editableDetails, status: e.target.value })
                    }
                  />
                </label>

                <label className="iminfo-label">
                  Invoice Date:
                  <input
                    type="text"
                    className="iminfo-input"
                    value={editableDetails.invoice_date}
                    onChange={(e) =>
                      setEditableDetails({ ...editableDetails, invoice_date: e.target.value })
                    }
                  />
                </label>

                <label className="iminfo-label">
                  Property Address:
                  <input
                    type="text"
                    className="iminfo-input"
                    value={editableDetails.property_address}
                    onChange={(e) =>
                      setEditableDetails({ ...editableDetails, property_address: e.target.value })
                    }
                  />
                </label>

                <label className="iminfo-label">
                  Our Ref:
                  <input
                    type="text"
                    className="iminfo-input"
                    value={editableDetails.our_ref}
                    onChange={(e) =>
                      setEditableDetails({ ...editableDetails, our_ref: e.target.value })
                    }
                  />
                </label>

                <label className="iminfo-label">
                  VAT No:
                  <input
                    type="text"
                    className="iminfo-input"
                    value={editableDetails.vat_no}
                    onChange={(e) =>
                      setEditableDetails({ ...editableDetails, vat_no: e.target.value })
                    }
                  />
                </label>

                <button className="iminfo-edit-submit-btn" onClick={handleSubmitEdit}>
                  Submit
                </button>
              </div>
            ) : (
              <div>
                <h2>Invoice #{invoice_number}</h2>
                <p>Client: {invoiceDetails.client}</p>
                <p>Status: {invoiceDetails.status}</p>
                <p>Invoice Date: {invoiceDetails.invoice_date}</p>
                <p>Property Address: {invoiceDetails.property_address}</p>
                <p>Our Ref: {invoiceDetails.our_ref}</p>
                <p>VAT No: {invoiceDetails.vat_no}</p>
                {/* No inline edit button here, since we have the top-right one */}
              </div>
            )}
          </div>

          <div className="iminfo-documents">
            <h3 className="iminfo-h3">Invoice Documents</h3>

            {/* Button group for "Generate Invoice" and "Delete Files" */}
            <div className="iminfo-documents-buttons">
                <button
                  className="iminfo-generate-documents-btn"
                  onClick={handleGenerateInvoiceDoc}
                >
                  Generate Invoice Document
                </button>
                <button
                  className="iminfo-delete-documents-btn"
                  onClick={handleDeleteSelectedFiles}
                  disabled={!files.some(file => file.isSelected)}
                >
                  Delete Files
                </button>
              </div>

            {successMessage && <p className="iminfo-success-message">{successMessage}</p>}

            {loadingFiles ? (
              <p>Loading files...</p>
            ) : fileError ? (
              <p>{fileError}</p>
            ) : (
              <div>
                <table className="iminfo-files-table">
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          checked={files.every(file => file.isSelected)}
                          onChange={handleSelectAllFiles} // Select All functionality
                        />
                      </th>
                      <th>Name</th>
                      <th>Owner</th>
                      <th>Recently Modified</th>
                    </tr>
                  </thead>
                  <tbody>
                    {files.map((file, index) => (
                      <tr key={index}>
                        <td>
                          <input
                            type="checkbox"
                            checked={file.isSelected || false}
                            onChange={() => handleSelectFile(index)} // Toggle individual selection
                          />
                        </td>
                        <td>
                          <a href={file.url} target="_blank" rel="noopener noreferrer">
                            {file.name}
                          </a>
                        </td>
                        <td>{invoiceDetails.client}</td>
                        <td>{file.lastModified}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
{/*
                {files.some(file => file.isSelected) && (
                  <button
                    className="iminfo-delete-documents-btn"
                    onClick={handleDeleteSelectedFiles} // Delete selected files
                  >
                    Delete Files
                  </button>
                )}
                  */}
              </div>
            )}
          </div>

          
          {/* Invoice Total */}
          <div className="iminfo-total">
            {/* 
              Temporarily remove or comment out the Edit/Cancel button:

              <button className="iminfo-total-edit-button" onClick={handleToggleEditTotals}>
                {isEditingTotals ? 'Cancel' : 'Edit'}
              </button>
            */}

            {/* The heading is pushed down via CSS so it doesn't overlap the button */}
            <h3 className="iminfo-h3">Invoice Total</h3>

            {loadingTotals ? (
              <p>Loading totals...</p>
            ) : totalsError ? (
              <p>{totalsError}</p>
            ) : (
              <>
                {isEditingTotals ? (
                  <div>
                    <label className="iminfo-total-label">
                      Total Charges:
                      <input
                        type="number"
                        className="iminfo-total-input"
                        value={editableTotals.total_charges}
                        onChange={(e) =>
                          setEditableTotals({ ...editableTotals, total_charges: e.target.value })
                        }
                      />
                    </label>

                    <label className="iminfo-total-label">
                      Total VAT Amount:
                      <input
                        type="number"
                        className="iminfo-total-input"
                        value={editableTotals.total_vat_amount}
                        onChange={(e) =>
                          setEditableTotals({ ...editableTotals, total_vat_amount: e.target.value })
                        }
                      />
                    </label>

                    <label className="iminfo-total-label">
                      Total Net Amount:
                      <input
                        type="number"
                        className="iminfo-total-input"
                        value={editableTotals.total_net_amount}
                        onChange={(e) =>
                          setEditableTotals({ ...editableTotals, total_net_amount: e.target.value })
                        }
                      />
                    </label>

                    <label className="iminfo-total-label">
                      Total Invoice Amount:
                      <input
                        type="number"
                        className="iminfo-total-input"
                        value={editableTotals.total_invoice_amount}
                        onChange={(e) =>
                          setEditableTotals({ ...editableTotals, total_invoice_amount: e.target.value })
                        }
                      />
                    </label>

                    <button className="iminfo-total-edit-submit-btn" onClick={handleSubmitEditTotals}>
                      Submit
                    </button>
                  </div>
                ) : (
                  <div>
                    <p>Total Charges: £{invoiceTotals.totalCharges}</p>
                    <p>Total VAT Amount: £{invoiceTotals.totalVatAmount}</p>
                    <p>Total Net Amount: £{invoiceTotals.totalNetAmount}</p>
                    <p>Total Invoice Amount: £{invoiceTotals.totalInvoiceAmount}</p>
                  </div>
                )}
              </>
            )}
          </div>





          {/* Invoice Display */}
            <div className="iminfo-display">
              <div className="iminfo-display-header">
                <h3 className="iminfo-id-h3">Invoice Details</h3>
                {/* New Flex Container for Buttons */}
                <div className="iminfo-display-buttons">
                  <button className="iminfo-add-fee-btn" onClick={handleOpenModal}>
                    Create Other Fee
                  </button>
                    <button
                      className="iminfo-delete-documents-btn"
                      onClick={handleDeleteCombinedRecords}
                      disabled={selectedCombinedRecords.length === 0} // Correct condition
                    >
                      Delete Files
                    </button>
                </div>
              </div>
              
              {/* Modal component */}
              <Modal 
                isOpen={isModalOpen} 
                onClose={handleCloseModal}
                invoiceNumber={invoiceDetails?.invoice_number}
                ourRef={invoiceDetails?.our_ref}
                submitAdditionalFee={submitAdditionalFee} // Pass the function here
              >
                {/* Form fields and submit logic are passed as children */}
                <form onSubmit={(e) => {
                  e.preventDefault(); // Prevent the default form submission behavior
                  submitAdditionalFee(); // Call the function passed as a prop
                }}>
                  <input 
                    className="tmd_modal_input" 
                    placeholder="Invoice Type" 
                    type="text"
                    value={additionalFeeData.invoiceType}
                    onChange={e => setAdditionalFeeData({...additionalFeeData, invoiceType: e.target.value})}
                  />
                  <input 
                    className="tmd_modal_input" 
                    placeholder="Charges" 
                    type="number"
                    value={additionalFeeData.charges}
                    onChange={e => setAdditionalFeeData({...additionalFeeData, charges: e.target.value})}
                  />
                  <input 
                    className="tmd_modal_input" 
                    placeholder="VAT Amount" 
                    type="text"
                    value={`£${additionalFeeData.vatAmount}`}
                    readOnly
                  />
                  <input 
                    className="tmd_modal_input" 
                    placeholder="Total Net Amount" 
                    type="text"
                    value={`£${additionalFeeData.totalNetAmount}`}
                    readOnly
                  />
                  <input 
                    className="tmd_modal_input" 
                    placeholder="Invoice Total" 
                    type="text"
                    value={`£${additionalFeeData.invoiceTotal}`}
                    readOnly
                  />
                </form>
              </Modal>


              {loadingInvoiceData ? (
                <p>Loading invoice details...</p>
              ) : invoiceDataError ? (
                <p>{invoiceDataError}</p>
              ) : (
                <table className="iminfo-id-table">
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          checked={invoiceData.length > 0 && selectedCombinedRecords.length === invoiceData.length}
                          onChange={handleSelectAllCombinedRecords}
                        />
                      </th>
                      <th>ID</th>
                      <th>Invoice Number</th>
                      <th>Our Ref</th>
                      <th>Invoice Type</th>
                      <th>Charges (£)</th>
                      <th>VAT Amount (£)</th>
                      <th>Total Net Amount (£)</th>
                      <th>Invoice Total (£)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceData.map((invoice, index) => (
                      <tr key={index}>
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedCombinedRecords.includes(invoice.id)}
                            onChange={() => handleSelectCombinedRecord(invoice.id)}
                          />
                        </td>
                        <td>{invoice.id}</td>
                        <td>{invoice.invoice_number}</td>
                        <td>{invoice.our_ref}</td>
                        <td>{invoice.invoice_type}</td>
                        <td>{invoice.charges}</td>
                        <td>{invoice.vat_amount}</td>
                        <td>{invoice.total_net_amount}</td>
                        <td>{invoice.invoice_total}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
            
        </div>
      </div>
      <DashboardFooter />
    </div>
  );
};

export default InvoiceInfo;
