import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import DashboardHeader from '../components/DashboardHeader';
import Sidebar from '../components/Sidebar';
import DashboardFooter from '../components/DashboardFooter';
import './CaseDetail.css';
import CaseDetailModal from '../components/CaseDetail_Modal'; 
import CaseDetailModalTenant from '../components/CaseDetail_Modal_AddTenant';  // Adjust the path as necessary
import Cookies from 'js-cookie';

const CaseDetail = () => {
  const { case_number } = useParams(); // Get the case number from the URL
  const navigate = useNavigate(); // For navigation/redirection
  const [caseDetails, setCaseDetails] = useState(null);  // Store the case details
  const [loadingCase, setLoadingCase] = useState(true);  // Loading state for case details
  const [error, setError] = useState(null);  // Error state
  const [successMessage, setSuccessMessage] = useState(null); // Success state for folder creation
  const [files, setFiles] = useState([]); // State to hold the files from S3
  const [loadingFiles, setLoadingFiles] = useState(true); // Loading state for files
  const [fileError, setFileError] = useState(null); // Error state for file fetching

  const [selectedDocType, setSelectedDocType] = useState('');  // State for selected document type

  const [dropdownOpen, setDropdownOpen] = useState(false);  // State to manage dropdown visibility
  const dropdownRef = useRef(null);  // Ref to help with detecting outside clicks

  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility

  const [additionalLandlords, setAdditionalLandlords] = useState([]); // State to store additional landlords
  const [additionalTenants, setAdditionalTenants] = useState([]); // State to store additional tenants
  const [selectedLandlordIndex, setSelectedLandlordIndex] = useState(0); // State to track selected landlord
  const [selectedTenantIndex, setSelectedTenantIndex] = useState(0); // State to track selected tenant


  const [isTenantModalOpen, setIsTenantModalOpen] = useState(false); // State to manage tenant modal visibility

  const [comments, setComments] = useState([]);  // Example state for comments data
  const [newComment, setNewComment] = useState(''); // Add this line to define the newComment state

  const [isEditing, setIsEditing] = useState(false); // State to track edit mode

  const handleToggleEditMode = () => {
    setIsEditing(!isEditing); // Toggle the edit mode
  };

  const [editableDetails, setEditableDetails] = useState({
    person: caseDetails?.person || '',
    email: caseDetails?.email || '',
    client_type: caseDetails?.client_type || '',
    status: caseDetails?.status || '',
    address: caseDetails?.address || '',
    city: caseDetails?.city || '',
    postcode: caseDetails?.postcode || '',
    property_address: caseDetails?.property_address || '',
    flat: caseDetails?.flat || '',        // Use optional chaining here
    housename: caseDetails?.housename || '' // Use optional chaining here
  });
  

  //Edit States and Handlers for the divs tenant info, additional landlord and additional tenant info
  const [isTenantEditing, setIsTenantEditing] = useState(false);
  const [isAddLandlordEditing, setIsAddLandlordEditing] = useState(false);
  const [isAddTenantEditing, setIsAddTenantEditing] = useState(false);

  const handleToggleTenantEditMode = () => {
    if (!isTenantEditing) {
      setEditableDetails((prevDetails) => ({
        ...prevDetails,
        tenant: {
          title: caseDetails.tenant?.title || '',
          given_name: caseDetails.tenant?.given_name || '',
          surname: caseDetails.tenant?.surname || '',
          email: caseDetails.tenant?.email || '',
          contact_number: caseDetails.tenant?.contact_number || '',
          gender: caseDetails.tenant?.gender || ''
        }
      }));
    }
    setIsTenantEditing(!isTenantEditing);
  };
  
  const handleToggleAddLandlordEditMode = () => {
    if (!isAddLandlordEditing) {
      const landlords = caseDetails.additional_landlords || [];
      if (landlords.length === 0) {
        // Initialize with one empty landlord object
        setAdditionalLandlords([
          {
            title: '',
            given_name: '',
            surname: '',
            email: '',
            contact_number: '',
          },
        ]);
      } else {
        setAdditionalLandlords(landlords);
      }
    }
    setIsAddLandlordEditing(!isAddLandlordEditing);
  };
  
  const handleToggleAddTenantEditMode = () => {
    if (!isAddTenantEditing) {
      const tenants = caseDetails.additional_tenants || [];
      if (tenants.length === 0) {
        // Initialize with one empty tenant object
        setAdditionalTenants([
          {
            addtent_title: '',
            addtent_given_name: '',
            addtent_surname: '',
            addtent_email: '',
            addtent_contact_number: '',
          },
        ]);
      } else {
        setAdditionalTenants(tenants);
      }
    }
    setIsAddTenantEditing(!isAddTenantEditing);
  };

  //Modals for pop up windows of Additional Landlord and Additional Tenants
  const handleOpenModal = () => {
      setIsModalOpen(true);
      setDropdownOpen(false); // Optionally close the dropdown when opening the modal
  };

  const handleCloseModal = () => {
      setIsModalOpen(false);
  };

  // Function to open the tenant modal
  const handleOpenTenantModal = () => {
    setIsTenantModalOpen(true);
    setDropdownOpen(false); // Optionally close the dropdown when opening the modal
  };

  // Function to close the tenant modal
  const handleCloseTenantModal = () => {
    setIsTenantModalOpen(false);
  };

  // Function to handle input changes
  const handleCommentChange = (event) => {
    setNewComment(event.target.value);  // Update the state when the user types in the comment input
  };

  //State and handlers for Drag and Drop
  const [isDragging, setIsDragging] = useState(false);

  const handleDragEnter = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    // Process the files
    const files = e.dataTransfer.files;
    uploadFilesToS3(files);
  };

  //State to track the upload status
  const [uploadProgress, setUploadProgress] = useState(0);

  // Convert the hyphenated case number back to slashes for display purposes
  const displayCaseNumber = case_number.replace(/-/g, '/');

  // Function to fetch files from S3 bucket (now including signed URLs)
  const fetchFiles = useCallback(async (token) => {
    setLoadingFiles(true);
    setFileError(null);
    
    try {
      const response = await axios.get(
        `https://app.trackmydocs.cloud/api/documents/list-files/${case_number}/`, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      
      if (response.status === 200 && response.data.files.length > 0) {
        const transformedFiles = response.data.files.map((file) => ({
          ...file,
          isSelected: false, // Initialize isSelected
        }));
        setFiles(transformedFiles);
      } else {
        setFileError('No folders or files found. Click "Generate Documents" to create the necessary files.');
      }
      
      setLoadingFiles(false);
    } catch (error) {
      setFileError('Error fetching files. Please try again later.');
      setLoadingFiles(false);
    }
  }, [case_number]);


  // Fetch case details and files when the component loads
  useEffect(() => {
    const fetchCaseDetails = async () => {
      const token = localStorage.getItem('access_token');
      if (!token) {
        navigate('/signin');
        return;
      }
  
      try {
        const response = await axios.get(`https://app.trackmydocs.cloud/api/clients/cases/${case_number}/`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Cache-Control': 'no-cache', // Prevent caching
            'Pragma': 'no-cache', // Prevent caching
            'Expires': '0', // Ensure the request is not cached
          },
        });
  
        if (response.status === 200) {
          setCaseDetails(response.data);
          setAdditionalLandlords(response.data.additional_landlords || []); // Set additional landlords if present
          setAdditionalTenants(response.data.additional_tenants || []); // Set additional tenants if present
          setLoadingCase(false);
          fetchFiles(token); // Fetch the files after the case details are successfully loaded
        } else {
          setError('Case not found');
          setLoadingCase(false);
        }
      } catch (error) {
        console.error('Error fetching case details: ', error);
        setError('Error fetching case details');
        setLoadingCase(false);
      }
    };
  
    fetchCaseDetails();
  }, [case_number, navigate, fetchFiles]);
  
    

  // New useEffect to update editableDetails once caseDetails are fetched
  useEffect(() => {
    if (caseDetails) {
      setEditableDetails({
        person: caseDetails.person || '',
        email: caseDetails.email || '',
        client_type: caseDetails.client_type || '',
        status: caseDetails.status || '', // Correct usage of status field
        address: caseDetails.address || '',
        city: caseDetails.city || '',
        postcode: caseDetails.postcode || '',
        property_address: caseDetails.property_address || '',
        flat: caseDetails.flat || '',        // Corrected landlord's flat
        housename: caseDetails.housename || '' // Corrected landlord's housename
      });
    }
  }, [caseDetails]);

  // Event handler for "Generate Documents" button
  const handleGenerateDocuments = async () => {
    const token = localStorage.getItem('access_token');

    if (!token) {
      navigate('/signin');
      return;
    }

    if (!selectedDocType) {
      alert('Please select a document type.');
      return;
    }

    let url = '';
    let requestData = { case_number };

    // Define document types mapping to their respective endpoints and additional data requirements
    const landlordDocuments = [
      'Landlord Client Care Letter',
      'Cover Letter - Landlord'
    ];

    const tenantDocuments = [
      'Tenant Client Care Letter',
      'S21 Notice CL Tenant',
      'S6 Notice - CL to Tenant',
      'S8 - 21 Notice - CL to Tenant',
      'S8 Non-Rent Arrears - CL to Tenant',
      'S8 Rent Arrears - CL to Tenant',
      'S13 Notice - CL to Tenant',
      'S48 Notice - CL to Tenant',
      'Notice to Quit - CL to Tenant',
      'S8 - Deposit Set Off Letter - Tenant',
      'Deposit Set Off Letter - Tenant',
      'NTQ Arrears - CL'
    ];

    const noticeDocuments = [
      'S13 Notice',
      'S21 Notice - Housing Act 1988',
      'Section 6 Notice',
      'Section 3 Notice',
      'Section 48 Notice (Landlord and Tenant Act 1987)',
      'Section 8 - G8, 10, 11',
      'Section 8 - G8, 10, 11, 12, 13, 14, 17',
      'Notice to Quit'
    ];

    if (landlordDocuments.includes(selectedDocType)) {
      url = 'https://app.trackmydocs.cloud/api/documents/generate-cl-landlord/';
      // Ensure that both case_number and document_type are sent in the request
      requestData = { 
        case_number, 
        document_type: selectedDocType 
      };
    } else if (tenantDocuments.includes(selectedDocType)) {
      url = 'https://app.trackmydocs.cloud/api/documents/generate-cl-tenant/';
      // Additional properties are sent for tenant documents
      requestData = { 
        case_number, 
        document_type: selectedDocType, 
        property_address: caseDetails.property_address, 
        person: caseDetails.person 
      };
    } else if (noticeDocuments.includes(selectedDocType)) {
      url = 'https://app.trackmydocs.cloud/api/documents/generate-notices/';
      requestData = { 
        case_number, 
        document_type: selectedDocType 
      };
    } else {
      alert('Invalid document type selected.');
      return;
    }
    
    try {
      const response = await axios.post(
        url, 
        requestData, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.status === 200 || response.status === 201) {
        setSuccessMessage(response.data.message || 'Document generated successfully!');
        alert(response.data.message || 'Document generated successfully!');
        fetchFiles(token);  // Refresh the file list after the document is created
      } else {
        alert('Unexpected response from the server.');
      }
    } catch (error) {
      // Check if the error message from backend is about tenant not found
      if (error.response && error.response.data && error.response.data.error === "There are no Tenants for this case found, please add a Tenant.") {
        alert(error.response.data.error); // Display the specific error
      } else {
        console.error('Error generating document:', error);
        alert('Error generating document. Please try again later.');
      }
    }
  };


  // Handler to submit form data to the backend to add additional landlord
  const handleAddLandlordSubmit = async (formData) => {
    try {
      // Attach case number directly to the formData without formatting
      formData.case_number = case_number; // Send case number directly as is

      const token = localStorage.getItem('access_token');  // Get auth token

      const response = await axios.post(
        'https://app.trackmydocs.cloud/api/clients/add-landlord/',
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      );

      if (response.status === 200 || response.status === 201) {
        alert('Landlord added successfully!');
        setSuccessMessage('Landlord added successfully!');
        handleCloseModal(); // Close the modal after successful submission
        window.location.reload(); // Reload the page to reflect the new changes
      } else {
        alert('Error adding landlord.');
      }
    } catch (error) {
      console.error('Error adding landlord:', error);
      alert('An error occurred. Please try again.');
    }
  };


  // Handler to submit form data to the backend to add additional tenant
  const handleAddTenantSubmit = async (formData) => {
    try {
        // Attach case number directly to the formData without formatting
        formData.case_number = case_number; // Send case number directly as is

        const token = localStorage.getItem('access_token');  // Get auth token

        const response = await axios.post(
            'https://app.trackmydocs.cloud/api/clients/add-tenant/',
            formData,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }
        );

        if (response.status === 200 || response.status === 201) {
            alert('Tenant added successfully!');
            setSuccessMessage('Tenant added successfully!');
            handleCloseTenantModal(); // Close the modal after successful submission
            window.location.reload(); // Reload the page to reflect the new changes
        } else {
            alert('Error adding tenant.');
        }
    } catch (error) {
        console.error('Error adding tenant:', error);
        alert('An error occurred. Please try again.');
    }
  };

  // Event handler for "Generate Invoices" button
  const handleGenerateInvoices = () => {
    navigate(`/gen-invoice/${case_number}`);
  };

  // Function to handle submitting a new comment
  const handleCommentSubmit = async () => {
    const token = localStorage.getItem('access_token');  // Get the auth token
    if (!newComment) {
      alert('Please enter a comment before submitting.');
      return;
    }

    try {
      const response = await axios.post(
        `https://app.trackmydocs.cloud/api/clients/cases/${case_number}/create-comment/`,
        { comment: newComment },  // Payload for the comment
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 201) {
        // Clear the input field after successful submission
        setNewComment("");
        // Fetch updated comments
        fetchComments();
      } else {
        alert('Error submitting the comment.');
      }
    } catch (error) {
      console.error('Error submitting comment:', error);
      alert('An error occurred while submitting the comment. Please try again.');
    }
  };

  // Fetch comments from the backend
  const fetchComments = useCallback(async () => {
    const token = localStorage.getItem('access_token');  // Get the auth token
    try {
      const response = await axios.get(
        `https://app.trackmydocs.cloud/api/clients/cases/${case_number}/get-comment/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      
      if (response.status === 200) {
        setComments(response.data);  // Update comments state with the data from the server
      } else {
        console.error('Failed to fetch comments');
      }
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  }, [case_number]);

  useEffect(() => {
    fetchComments();  // Fetch comments when the component mounts
  }, [fetchComments]);


  // Function to toggle the dropdown
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  // Handle clicking outside the dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    // Attach the event listener to the document
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handle Edit states 
  const handleSubmitEdit = async () => {
    const token = localStorage.getItem('access_token');
    if (!token) {
      alert('You must be logged in to update case details.');
      return;
    }

    try {
      const response = await axios.put(
        `https://app.trackmydocs.cloud/api/clients/cases/${case_number}/update/`, // API endpoint for updating case details
        editableDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setIsEditing(false);  // Exit edit mode
        setCaseDetails(response.data);  // Update the state with the new case details
        alert('Case details updated successfully!');
        window.location.reload();
      } else {
        alert('Error updating case details.');
      }
    } catch (error) {
      console.error('Error updating case details:', error);
      alert('An error occurred while updating the case. Please try again.');
    }
  };

  //Handle Edit section 
  const handleSubmitEditSection = async (section) => {
    const token = localStorage.getItem('access_token');
    if (!token) {
      alert('You must be logged in to update case details.');
      return;
    }
  
    let requestData = {};
  
    // Set the request data depending on the section
    switch (section) {
      case 'tenant-info':
        requestData = {
          section: 'tenant-info', // Identifier for the backend
          tenant_details: { ...editableDetails.tenant },
        };
        break;
  
      case 'add-landlord':
        requestData = {
          section: 'add-landlord', // Identifier for the backend
          additional_landlords: additionalLandlords,
        };
        break;
  
      case 'add-tenant':
        requestData = {
          section: 'add-tenant', // Identifier for the backend
          additional_tenants: additionalTenants,
        };
        break;
  
      default:
        console.error('Invalid section identifier provided');
        return;
    }
  
    try {
      const response = await axios.put(
        `https://app.trackmydocs.cloud/api/clients/cases/${case_number}/update-section/`, // Single common endpoint
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      if (response.status === 200) {
        alert(`${section.replace('-', ' ')} updated successfully!`);
        window.location.reload(); // Refresh to reflect changes
      } else {
        alert(`Error updating ${section.replace('-', ' ')}.`);
      }
    } catch (error) {
      console.error(`Error updating ${section}:`, error);
      alert('An error occurred while updating the information. Please try again.');
    }
  };
  

  //Handle delete for the file table
  // Toggle individual file selection
  const handleSelectFile = (index) => {
    const newFiles = files.map((file, idx) => {
      if (idx === index) {
        return { ...file, isSelected: !file.isSelected };
      }
      return file;
    });
    setFiles(newFiles);
  };

  // Toggle all file selections
  const handleSelectAllFiles = () => {
    const allSelected = files.every(file => file.isSelected);
    const newFiles = files.map(file => ({
      ...file,
      isSelected: !allSelected
    }));
    setFiles(newFiles);
  };
  
  const handleDeleteSelectedFiles = async () => {
    const selectedFiles = files.filter(file => file.isSelected).map(file => file.name);
    const token = localStorage.getItem('access_token');
  
    if (selectedFiles.length === 0) {
      alert('No files selected for deletion.');
      return;
    }
  
    try {
      const response = await axios.post(
        'https://app.trackmydocs.cloud/api/documents/cd-del-files/',
        {
          case_number,
          files: selectedFiles
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      );
  
      if (response.status === 200) {
        alert(response.data.message);
        // Remove deleted files from state
        const remainingFiles = files.filter(file => !file.isSelected);
        setFiles(remainingFiles);
      } else {
        alert('Failed to delete files. Please try again.');
      }
    } catch (error) {
      console.error('Error deleting files:', error);
      alert('An error occurred while deleting the files. Please try again.');
    }
  };
  
  //Function Handler to attach files to email 
  const handleAttachToEmail = async () => {
    const token = localStorage.getItem('access_token');
    const csrftoken = Cookies.get('csrftoken');
    const selectedFiles = files.filter(file => file.isSelected);
  
    if (!token) {
      alert('Please sign in.');
      navigate('/signin');
      return;
    }
  
    if (selectedFiles.length === 0) {
      alert('No files selected to attach.');
      return;
    }
  
    try {
      const response = await axios.post(
        'https://app.trackmydocs.cloud/api/documents/attach-to-email/',
        {
          case_number, // e.g. 'SC-4029-25'
          files: selectedFiles.map(f => f.name),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'X-CSRFToken': csrftoken,
          },
          responseType: 'blob', // We want a .eml file
        }
      );
  
      // Once we get the .eml file back, create a download URL:
      const fileBlob = new Blob([response.data], { type: 'message/rfc822' });
      const fileURL = window.URL.createObjectURL(fileBlob);
  
      // Prompt immediate download:
      const tempLink = document.createElement('a');
      tempLink.href = fileURL;
      tempLink.setAttribute('download', 'my_attachments.eml');
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
  
      // Or you could do window.open(fileURL) in some browsers,
      // but .eml typically still downloads anyway.
    } catch (err) {
      console.error('Error attaching files to email:', err);
      alert('Unable to attach files. Please try again.');
    }
  };
  
  

  //Function Handler to upload any file into the S3 case folder
  const uploadFilesToS3 = async (files) => {
    const token = localStorage.getItem('access_token');
    const formData = new FormData();
  
    // Append each file to the form data
    Array.from(files).forEach(file => {
      formData.append('files', file); // 'files' is the key your backend expects for uploads
    });
  
    // Append case_number to the form data
    formData.append('case_number', case_number);
  
    try {
      const response = await axios.post(
        'https://app.trackmydocs.cloud/api/documents/upload/', 
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
          // Track the upload progress
          onUploadProgress: (progressEvent) => {
            const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentage);
          },
        }
      );
  
      if (response.status === 200) {
        console.log("Upload successful", response.data);
        setUploadProgress(0); // Reset progress after completion
        window.location.reload(); // Refresh page
      } else {
        console.error("Failed to upload files");
      }
    } catch (err) {
      console.error("Error uploading to backend", err);
      setUploadProgress(0); // Reset on error
    }
  };  
   
  // Function to format date from 'YYYY-MM-DD HH:mm:ss' to 'DD-MM-YYYY HH:mm:ss'
  const formatDate = (dateString) => {
    const [datePart, timePart] = dateString.split(' ');
    const [year, month, day] = datePart.split('-');
    return `${day}-${month}-${year} ${timePart}`;
  };

  // Loading state for case details
  if (loadingCase) return <div>Loading case details...</div>;

  // Error state for case details
  if (error) return <div>{error}</div>;

  return (
    <div className="case-detail-page-container">
      <DashboardHeader />
      <Sidebar />

      <div className="case-detail-main-content">
        <div className="case-detail-content-area">
        <div className="case-detail-container">
          <h2>{displayCaseNumber}</h2>
          <button className="settings-button" onClick={toggleDropdown} title="Settings">
            Edit
          </button>
          {dropdownOpen && (
            <div className="case-detail-dropdown-menu">
           <button onClick={handleOpenModal}>Add Landlord</button> 
              <button onClick={handleOpenTenantModal}>Add Tenant</button>
              <button onClick={handleToggleEditMode}>{isEditing ? 'Cancel' : 'Edit Case'}</button>  {/* Toggle Edit Mode */}
            </div>
          )}
          {caseDetails.created_by && <h4>{caseDetails.created_by}</h4>}

          {isEditing ? (
            <>
              <label className="case-detail-label">
                Person:
                <input
                  type="text"
                  className="case-detail-input"
                  value={editableDetails.person}
                  onChange={(e) => setEditableDetails({ ...editableDetails, person: e.target.value })}
                />
              </label>
              <label className="case-detail-label">
                Email:
                <input
                  type="email"
                  className="case-detail-input"
                  value={editableDetails.email}
                  onChange={(e) => setEditableDetails({ ...editableDetails, email: e.target.value })}
                />
              </label>
              <label className="case-detail-label">
                Client Type:
                <input
                  type="text"
                  className="case-detail-input"
                  value={editableDetails.client_type}
                  onChange={(e) => setEditableDetails({ ...editableDetails, client_type: e.target.value })}
                />
              </label>
              <label className="case-detail-label">
                Flat:
                <input
                  type="text"
                  className="case-detail-input"
                  value={editableDetails.flat}  // Corrected field name
                  onChange={(e) => setEditableDetails({ ...editableDetails, flat: e.target.value })}  // Corrected field name
                />
              </label>
              <label className="case-detail-label">
                Housename:
                <input
                  type="text"
                  className="case-detail-input"
                  value={editableDetails.housename}  // Corrected field name
                  onChange={(e) => setEditableDetails({ ...editableDetails, housename: e.target.value })}  // Corrected field name
                />
              </label>
              <label className="case-detail-label">
                Address:
                <input
                  type="text"
                  className="case-detail-input"
                  value={editableDetails.address}
                  onChange={(e) => setEditableDetails({ ...editableDetails, address: e.target.value })}
                />
              </label>
              <label className="case-detail-label">
                City:
                <input
                  type="text"
                  className="case-detail-input"
                  value={editableDetails.city}
                  onChange={(e) => setEditableDetails({ ...editableDetails, city: e.target.value })}
                />
              </label>
              <label className="case-detail-label">
                Postcode:
                <input
                  type="text"
                  className="case-detail-input"
                  value={editableDetails.postcode}
                  onChange={(e) => setEditableDetails({ ...editableDetails, postcode: e.target.value })}
                />
              </label>
              <label className="case-detail-label">
                Property Address:
                <input
                  type="text"
                  className="case-detail-input"
                  value={editableDetails.property_address}
                  onChange={(e) => setEditableDetails({ ...editableDetails, property_address: e.target.value })}
                />
              </label>
              <button className="case-detail-edit-submit-btn" onClick={handleSubmitEdit}>Submit</button>  {/* Submit changes */}
            </>
          ) : (
            <>
              <p>Landlord Name: {caseDetails.person}</p>
              <p>Email: <a href={`mailto:${caseDetails.email}`} target="_blank" rel="noopener noreferrer">{caseDetails.email}</a></p>           
              <p>Client Type: {caseDetails.client_type}</p>
              <p>Status: {caseDetails.status}</p>
              <p>Flat: {caseDetails.flat}</p> {/* Corrected field name */}
              <p>Housename: {caseDetails.housename}</p> {/* Corrected field name */}
              <p>No & Street: {caseDetails.address}</p>
              <p>City: {caseDetails.city}</p>
              <p>Postcode: {caseDetails.postcode}</p>
              <p>Property Address: {caseDetails.property_address}</p> 
            </>
          )}

          {/* Conditionally render additional landlords */}
          {additionalLandlords.length > 0 && (
            <div className="case-detail-additional-landlord-disp">
              <p>Additional Landlords:</p>
              <ul>
                {additionalLandlords.map((landlord, index) => (
                  <li key={index}>
                    {landlord.title} {landlord.given_name} {landlord.surname} - {landlord.email} ({landlord.contact_number})
                  </li>
                ))}
              </ul>
            </div>
          )}
          </div>
          
          {/* Modal Component - placed here */}
          <CaseDetailModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            onSubmit={handleAddLandlordSubmit}  // Pass the handler function to the modal
          />

          {/* New Modal Component for adding tenant */}
          <CaseDetailModalTenant
              isOpen={isTenantModalOpen}
              onClose={handleCloseTenantModal}
              onSubmit={handleAddTenantSubmit}  // Pass the handler function to the modal
          />
          
          {/* Documents Section */}
          <div className="case-detail-documents"
                onDragOver={handleDragOver}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}>

            {isDragging &&
              <div className="drag-overlay">
                Drop files here...
              </div>
            }

            {/* Upload Progress Overlay */}
            {uploadProgress > 0 && uploadProgress < 100 && (
              <div className="upload-progress-overlay">
                <p>Uploading: {uploadProgress}%</p>
              </div>
            )}       

            {!isDragging && 
            <div>
              <h3 className="case-detail-h3">Documents</h3>
              
              {/* Dropdown for selecting document type */}
              <select
                value={selectedDocType}
                onChange={(e) => setSelectedDocType(e.target.value)}
                className="document-type-dropdown"
              >
                <option value="">Select Document Type</option>
                <optgroup label="Landlord Documents">
                  <option value="Landlord Client Care Letter">Landlord Client Care Letter</option>
                  <option value="Cover Letter - Landlord">Cover Letter - Landlord</option>
                </optgroup>
                <optgroup label="Tenant Documents">                
                  <option value="S21 Notice CL Tenant">Section 21 Notice Cover Letter - Tenant</option>
                  <option value="S6 Notice - CL to Tenant">Section 6 Notice Cover Letter - Tenant</option>
                  <option value="S8 - 21 Notice - CL to Tenant">Section 8 & 21 Notice Cover Letter - Tenant</option>
                  <option value="S8 Non-Rent Arrears - CL to Tenant">Section 8 Non-Rent Arrears Cover Letter - Tenant</option>
                  <option value="S8 Rent Arrears - CL to Tenant">Section 8 Rent Arrears Cover Letter - Tenant</option>
                  <option value="S13 Notice - CL to Tenant">Section 13 Notice Cover Letter - Tenant</option>
                  <option value="S48 Notice - CL to Tenant">Section 48 Notice Cover Letter - Tenant</option>
                  <option value="Notice to Quit - CL to Tenant">Notice to Quit - Cover Letter to Tenant</option>
                  <option value="S8 - Deposit Set Off Letter - Tenant">Section 8 - Deposit Set Off Letter - Tenant</option>
                  <option value="Deposit Set Off Letter - Tenant">Deposit Set Off Letter - Tenant</option>
                  <option value="NTQ Arrears - CL">Notice to Quit & Arrears - Cover Letter</option>
                </optgroup>
                <optgroup label="Notices">
                  <option value="S13 Notice">Section 13 Notice</option>
                  <option value="S21 Notice - Housing Act 1988">Section 21 Notice (Housing Act 1988)</option>
                  <option value="Section 6 Notice">Section 6 Notice</option>
                  <option value="Section 3 Notice">Section 3 Notice</option>
                  <option value="Section 48 Notice (Landlord and Tenant Act 1987)">Section 48 Notice (Landlord and Tenant Act 1987)</option>
                  <option value="Section 8 - G8, 10, 11">Section 8 - G8, 10, 11</option>
                  <option value="Section 8 - G8, 10, 11, 12, 13, 14, 17">Section 8 - G8, 10, 11, 12, 13, 14, 17</option>
                  <option value="Notice to Quit">Notice to Quit</option>
                </optgroup>
              </select>
              
              <button 
                className="generate-documents-btn" 
                onClick={handleGenerateDocuments}
                disabled={!selectedDocType}
              >
                Generate Document
              </button>

              { /* ... in the JSX near the Delete button ... */ }
              {files.some(file => file.isSelected) && (
                <>
                  <button 
                    className="delete-documents-btn" 
                    onClick={handleDeleteSelectedFiles}
                  >
                    Delete Files
                  </button>

                  {/* NEW: Attach to Email Button */}
                  <button
                    className="attach-to-email-btn"
                    onClick={handleAttachToEmail}
                  >
                    Attach to Email
                  </button>
                </>
              )}
              
              {/* Display success or error message */}
              {successMessage && <p className="success-message">{successMessage}</p>}

              {/* Files Table */}
              {loadingFiles ? (
                <p>Loading files...</p>
              ) : fileError ? (
                <p>{fileError}</p>
              ) : (
                <table className="files-table">
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          checked={files.every(file => file.isSelected)}
                          onChange={handleSelectAllFiles} // Function to toggle all checkboxes
                        />
                      </th>
                      <th>Name</th>
                      <th>Recently Modified</th>
                    </tr>
                  </thead>
                  <tbody>
                    {files.map((file, index) => (
                      <tr key={index}>
                        <td>
                          <input
                              type="checkbox"
                              checked={file.isSelected || false} // Add a new field to manage selection state
                              onChange={() => handleSelectFile(index)} // We will define this function to toggle selection
                          />
                        </td>
                        <td>
                          <a href={file.url} target="_blank" rel="noopener noreferrer">
                            {file.name}
                          </a>
                        </td>
                        <td>{formatDate(file.lastModified)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>}
          </div>

          {/* Invoices Section */}
          <div className="case-detail-invoices">
            <h3>Landlord Advice Fee</h3>
            <button 
              className="generate-documents-btn" 
              onClick={handleGenerateInvoices}
            >
              Create Fee
            </button>
          </div>

          {/* Tenant Info Section */}
          <div className="case-detail-tenant-info">
            <h3>Tenant Information</h3>
            <button onClick={handleToggleTenantEditMode}>
              {isTenantEditing ? 'Cancel' : 'Edit Tenant'}
            </button>
            {isTenantEditing ? (
              <>
                <label className="case-detail-label">
                  Title:
                  <input
                    type="text"
                    className="case-detail-input"
                    value={editableDetails.tenant?.title || ''}
                    onChange={(e) =>
                      setEditableDetails({
                        ...editableDetails,
                        tenant: { ...editableDetails.tenant, title: e.target.value },
                      })
                    }
                  />
                </label>
                <label className="case-detail-label">
                  Given Name:
                  <input
                    type="text"
                    className="case-detail-input"
                    value={editableDetails.tenant?.given_name || ''}
                    onChange={(e) =>
                      setEditableDetails({
                        ...editableDetails,
                        tenant: { ...editableDetails.tenant, given_name: e.target.value },
                      })
                    }
                  />
                </label>
                <label className="case-detail-label">
                  Surname:
                  <input
                    type="text"
                    className="case-detail-input"
                    value={editableDetails.tenant?.surname || ''}
                    onChange={(e) =>
                      setEditableDetails({
                        ...editableDetails,
                        tenant: { ...editableDetails.tenant, surname: e.target.value },
                      })
                    }
                  />
                </label>
                <label className="case-detail-label">
                  Email:
                  <input
                    type="email"
                    className="case-detail-input"
                    value={editableDetails.tenant?.email || ''}
                    onChange={(e) =>
                      setEditableDetails({
                        ...editableDetails,
                        tenant: { ...editableDetails.tenant, email: e.target.value },
                      })
                    }
                  />
                </label>
                <label className="case-detail-label">
                  Contact Number:
                  <input
                    type="text"
                    className="case-detail-input"
                    value={editableDetails.tenant?.contact_number || ''}
                    onChange={(e) =>
                      setEditableDetails({
                        ...editableDetails,
                        tenant: { ...editableDetails.tenant, contact_number: e.target.value },
                      })
                    }
                  />
                </label>
                <label className="case-detail-label">
                  Gender:
                  <input
                    type="text"
                    className="case-detail-input"
                    value={editableDetails.tenant?.gender || ''}
                    onChange={(e) =>
                      setEditableDetails({
                        ...editableDetails,
                        tenant: { ...editableDetails.tenant, gender: e.target.value },
                      })
                    }
                  />
                </label>
                <button onClick={() => handleSubmitEditSection('tenant-info')}>Submit</button>
              </>
            ) : (
              <>
                {caseDetails.tenant && (
                  <>
                    <p>
                      <span className="case-detail-ti-label">Title:</span>{' '}
                      <span className="case-detail-ti-data">{caseDetails.tenant.title}</span>
                    </p>
                    <p>
                      <span className="case-detail-ti-label">Given Name:</span>{' '}
                      <span className="case-detail-ti-data">{caseDetails.tenant.given_name}</span>
                    </p>
                    <p>
                      <span className="case-detail-ti-label">Surname:</span>{' '}
                      <span className="case-detail-ti-data">{caseDetails.tenant.surname}</span>
                    </p>
                    <p>
                      <span className="case-detail-ti-label">Email:</span>{' '}
                      <span className="case-detail-ti-data">{caseDetails.tenant.email}</span>
                    </p>
                    <p>
                      <span className="case-detail-ti-label">Contact Number:</span>{' '}
                      <span className="case-detail-ti-data">{caseDetails.tenant.contact_number}</span>
                    </p>
                    <p>
                      <span className="case-detail-ti-label">Gender:</span>{' '}
                      <span className="case-detail-ti-data">{caseDetails.tenant.gender}</span>
                    </p>
                  </>
                )}
              </>
            )}
          </div>

          {/* Additional Landlord Info Section */}
          <div className="case-detail-addlandlord-info">
            <h3>Additional Landlords</h3>
            <button onClick={handleToggleAddLandlordEditMode}>
              {isAddLandlordEditing ? 'Cancel' : 'Edit Additional Landlords'}
            </button>
            {isAddLandlordEditing ? (
              // Edit mode rendering
              <>
                {additionalLandlords.map((landlord, index) => (
                  <div key={index} className="additional-landlord-form">
                    <label className="case-detail-label">
                      Title:
                      <input
                        type="text"
                        className="case-detail-input"
                        value={landlord.title}
                        onChange={(e) => {
                          const updatedLandlords = [...additionalLandlords];
                          updatedLandlords[index].title = e.target.value;
                          setAdditionalLandlords(updatedLandlords);
                        }}
                      />
                    </label>
                    <label className="case-detail-label">
                      Given Name:
                      <input
                        type="text"
                        className="case-detail-input"
                        value={landlord.given_name}
                        onChange={(e) => {
                          const updatedLandlords = [...additionalLandlords];
                          updatedLandlords[index].given_name = e.target.value;
                          setAdditionalLandlords(updatedLandlords);
                        }}
                      />
                    </label>
                    <label className="case-detail-label">
                      Surname:
                      <input
                        type="text"
                        className="case-detail-input"
                        value={landlord.surname}
                        onChange={(e) => {
                          const updatedLandlords = [...additionalLandlords];
                          updatedLandlords[index].surname = e.target.value;
                          setAdditionalLandlords(updatedLandlords);
                        }}
                      />
                    </label>
                    <label className="case-detail-label">
                      Email:
                      <input
                        type="email"
                        className="case-detail-input"
                        value={landlord.email}
                        onChange={(e) => {
                          const updatedLandlords = [...additionalLandlords];
                          updatedLandlords[index].email = e.target.value;
                          setAdditionalLandlords(updatedLandlords);
                        }}
                      />
                    </label>
                    <label className="case-detail-label">
                      Contact Number:
                      <input
                        type="text"
                        className="case-detail-input"
                        value={landlord.contact_number}
                        onChange={(e) => {
                          const updatedLandlords = [...additionalLandlords];
                          updatedLandlords[index].contact_number = e.target.value;
                          setAdditionalLandlords(updatedLandlords);
                        }}
                      />
                    </label>
                  </div>
                ))}
                <button onClick={() => handleSubmitEditSection('add-landlord')}>Submit</button>
              </>
            ) : (
              // Non-edit mode rendering
              <>
                {caseDetails.additional_landlords && caseDetails.additional_landlords.length > 0 ? (
                  <>
                    {caseDetails.additional_landlords.length > 1 ? (
                      <>
                        <select
                          className="case-detail-select"
                          value={selectedLandlordIndex}
                          onChange={(e) => setSelectedLandlordIndex(Number(e.target.value))}
                        >
                          {caseDetails.additional_landlords.map((landlord, index) => (
                            <option key={index} value={index}>
                              {landlord.title} {landlord.given_name} {landlord.surname}
                            </option>
                          ))}
                        </select>
                        <div className="additional-landlord-details">
                          <p>
                            <span className="case-detail-ti-label">Title:</span>{' '}
                            <span className="case-detail-ti-data">
                              {caseDetails.additional_landlords[selectedLandlordIndex].title}
                            </span>
                          </p>
                          <p>
                            <span className="case-detail-ti-label">Given Name:</span>{' '}
                            <span className="case-detail-ti-data">
                              {caseDetails.additional_landlords[selectedLandlordIndex].given_name}
                            </span>
                          </p>
                          <p>
                            <span className="case-detail-ti-label">Surname:</span>{' '}
                            <span className="case-detail-ti-data">
                              {caseDetails.additional_landlords[selectedLandlordIndex].surname}
                            </span>
                          </p>
                          <p>
                            <span className="case-detail-ti-label">Email:</span>{' '}
                            <span className="case-detail-ti-data">
                              {caseDetails.additional_landlords[selectedLandlordIndex].email}
                            </span>
                          </p>
                          <p>
                            <span className="case-detail-ti-label">Contact Number:</span>{' '}
                            <span className="case-detail-ti-data">
                              {caseDetails.additional_landlords[selectedLandlordIndex].contact_number}
                            </span>
                          </p>
                        </div>
                      </>
                    ) : (
                      // Only one additional landlord, display details directly
                      <>
                        <div className="additional-landlord-details">
                          <p>
                            <span className="case-detail-ti-label">Title:</span>{' '}
                            <span className="case-detail-ti-data">
                              {caseDetails.additional_landlords[0].title}
                            </span>
                          </p>
                          <p>
                            <span className="case-detail-ti-label">Given Name:</span>{' '}
                            <span className="case-detail-ti-data">
                              {caseDetails.additional_landlords[0].given_name}
                            </span>
                          </p>
                          <p>
                            <span className="case-detail-ti-label">Surname:</span>{' '}
                            <span className="case-detail-ti-data">
                              {caseDetails.additional_landlords[0].surname}
                            </span>
                          </p>
                          <p>
                            <span className="case-detail-ti-label">Email:</span>{' '}
                            <span className="case-detail-ti-data">
                              {caseDetails.additional_landlords[0].email}
                            </span>
                          </p>
                          <p>
                            <span className="case-detail-ti-label">Contact Number:</span>{' '}
                            <span className="case-detail-ti-data">
                              {caseDetails.additional_landlords[0].contact_number}
                            </span>
                          </p>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <p>No additional landlords.</p>
                )}
              </>
            )}
          </div>



          {/* Additional Tenant Info Section */}
          <div className="case-detail-addtenant-info">
            <h3>Additional Tenants</h3>
            <button onClick={handleToggleAddTenantEditMode}>
              {isAddTenantEditing ? 'Cancel' : 'Edit Additional Tenants'}
            </button>
            {isAddTenantEditing ? (
              // Edit mode rendering
              <>
                {additionalTenants.map((tenant, index) => (
                  <div key={index} className="additional-tenant-form">
                    <label className="case-detail-label">
                      Title:
                      <input
                        type="text"
                        className="case-detail-input"
                        value={tenant.addtent_title}
                        onChange={(e) => {
                          const updatedTenants = [...additionalTenants];
                          updatedTenants[index].addtent_title = e.target.value;
                          setAdditionalTenants(updatedTenants);
                        }}
                      />
                    </label>
                    <label className="case-detail-label">
                      Given Name:
                      <input
                        type="text"
                        className="case-detail-input"
                        value={tenant.addtent_given_name}
                        onChange={(e) => {
                          const updatedTenants = [...additionalTenants];
                          updatedTenants[index].addtent_given_name = e.target.value;
                          setAdditionalTenants(updatedTenants);
                        }}
                      />
                    </label>
                    <label className="case-detail-label">
                      Surname:
                      <input
                        type="text"
                        className="case-detail-input"
                        value={tenant.addtent_surname}
                        onChange={(e) => {
                          const updatedTenants = [...additionalTenants];
                          updatedTenants[index].addtent_surname = e.target.value;
                          setAdditionalTenants(updatedTenants);
                        }}
                      />
                    </label>
                    <label className="case-detail-label">
                      Email:
                      <input
                        type="email"
                        className="case-detail-input"
                        value={tenant.addtent_email}
                        onChange={(e) => {
                          const updatedTenants = [...additionalTenants];
                          updatedTenants[index].addtent_email = e.target.value;
                          setAdditionalTenants(updatedTenants);
                        }}
                      />
                    </label>
                    <label className="case-detail-label">
                      Contact Number:
                      <input
                        type="text"
                        className="case-detail-input"
                        value={tenant.addtent_contact_number}
                        onChange={(e) => {
                          const updatedTenants = [...additionalTenants];
                          updatedTenants[index].addtent_contact_number = e.target.value;
                          setAdditionalTenants(updatedTenants);
                        }}
                      />
                    </label>
                  </div>
                ))}
                <button onClick={() => handleSubmitEditSection('add-tenant')}>Submit</button>
              </>
            ) : (
              // Non-edit mode rendering
              <>
                {caseDetails.additional_tenants && caseDetails.additional_tenants.length > 0 ? (
                  <>
                    {caseDetails.additional_tenants.length > 1 ? (
                      <>
                        <select
                          className="case-detail-select"
                          value={selectedTenantIndex}
                          onChange={(e) => setSelectedTenantIndex(Number(e.target.value))}
                        >
                          {caseDetails.additional_tenants.map((tenant, index) => (
                            <option key={index} value={index}>
                              {tenant.addtent_title} {tenant.addtent_given_name} {tenant.addtent_surname}
                            </option>
                          ))}
                        </select>
                        <div className="additional-tenant-details">
                          <p>
                            <span className="case-detail-ti-label">Title:</span>{' '}
                            <span className="case-detail-ti-data">
                              {caseDetails.additional_tenants[selectedTenantIndex].addtent_title}
                            </span>
                          </p>
                          <p>
                            <span className="case-detail-ti-label">Given Name:</span>{' '}
                            <span className="case-detail-ti-data">
                              {caseDetails.additional_tenants[selectedTenantIndex].addtent_given_name}
                            </span>
                          </p>
                          <p>
                            <span className="case-detail-ti-label">Surname:</span>{' '}
                            <span className="case-detail-ti-data">
                              {caseDetails.additional_tenants[selectedTenantIndex].addtent_surname}
                            </span>
                          </p>
                          <p>
                            <span className="case-detail-ti-label">Email:</span>{' '}
                            <span className="case-detail-ti-data">
                              {caseDetails.additional_tenants[selectedTenantIndex].addtent_email}
                            </span>
                          </p>
                          <p>
                            <span className="case-detail-ti-label">Contact Number:</span>{' '}
                            <span className="case-detail-ti-data">
                              {caseDetails.additional_tenants[selectedTenantIndex].addtent_contact_number}
                            </span>
                          </p>
                        </div>
                      </>
                    ) : (
                      // Only one additional tenant, display details directly
                      <>
                        <div className="additional-tenant-details">
                          <p>
                            <span className="case-detail-ti-label">Title:</span>{' '}
                            <span className="case-detail-ti-data">
                              {caseDetails.additional_tenants[0].addtent_title}
                            </span>
                          </p>
                          <p>
                            <span className="case-detail-ti-label">Given Name:</span>{' '}
                            <span className="case-detail-ti-data">
                              {caseDetails.additional_tenants[0].addtent_given_name}
                            </span>
                          </p>
                          <p>
                            <span className="case-detail-ti-label">Surname:</span>{' '}
                            <span className="case-detail-ti-data">
                              {caseDetails.additional_tenants[0].addtent_surname}
                            </span>
                          </p>
                          <p>
                            <span className="case-detail-ti-label">Email:</span>{' '}
                            <span className="case-detail-ti-data">
                              {caseDetails.additional_tenants[0].addtent_email}
                            </span>
                          </p>
                          <p>
                            <span className="case-detail-ti-label">Contact Number:</span>{' '}
                            <span className="case-detail-ti-data">
                              {caseDetails.additional_tenants[0].addtent_contact_number}
                            </span>
                          </p>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <p>No additional tenants.</p>
                )}
              </>
            )}
          </div>
  

          {/* Comments Section */}
          <div className="case-detail-comments-container">
            <h3 className="case-detail-comments-title">Activity</h3>
            <div className="case-detail-comments-timeline">
              {comments.map((comment, index) => (
                <div key={index} className="case-detail-comments-entry">
                  <div className="case-detail-comments-dot-line"></div>
                  <p className="case-detail-comments-comment">{comment.comment}</p>
                  <p className="comment-meta">- {comment.user_name} on {comment.created_at}</p>
                </div>
              ))}
            </div>
            <div className="case-detail-comments-input">
              <input
                type="text"
                placeholder="Write a comment..."
                className="case-detail-comments-text-input"
                value={newComment}  // Bind the input value to the newComment state
                onChange={handleCommentChange}  // Handle input changes
              />
              <button className="case-detail-comments-submit-btn" onClick={handleCommentSubmit}>
                Submit Comment
              </button>
            </div>
          </div>

        </div>
      </div>
      <DashboardFooter />
    </div>
  );
};

export default CaseDetail;
